<template>
    <div class="main-wrapper">
        <div class="subject-header">
            <div class="select-left">
                <div class="select">
                    <span class="select-text">分类：</span>
                    <el-cascader
                        v-model="category_id"
                        style="width: 200px"
                        :props="keyObj"
                        :options="classList"
                        @change="changeClass"
                        clearable
                        placeholder="请选择分类"
                    ></el-cascader>
                </div>
                <div class="select">
                    <span class="select-text">题型：</span>
                    <el-select v-model="type_id" placeholder="请选择" style="width: 200px" clearable @change="changeType">
                        <el-option
                            v-for="item in subjectTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="select">
                    <span class="select-text">难度：</span>
                    <el-select v-model="difficulty_id" placeholder="请选择" style="width: 200px" clearable @change="changeDeep">
                        <el-option v-for="item in topicDeepList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="select-left">
                <div class="select">
                    <el-input
                        placeholder="请输入内容"
                        v-model="like"
                        style="width: 300px"
                        class="searchInput"
                        @keyup.enter.native="changeSearch"
                    >
                        <el-button slot="append" icon="el-icon-search" @click="changeSearch"></el-button>
                    </el-input>
                </div>
                <div class="select" style="margin-left: 20px">
                    <el-button type="primary" @click="importBtn" plain>导入试题</el-button>
                </div>
                <div class="select" style="margin-left: 20px">
                    <el-button type="primary" @click="addBtn"><i class="el-icon-plus"></i> 添加试题</el-button>
                </div>
            </div>
        </div>

        <el-table :data="subjectListTable" class="customTable" style="width: 100%; flex: 1" height="1%">
            <el-table-column label="题文" min-width="150">
                <template slot-scope="scope">
                    <el-tooltip placement="top-start" popper-class="subject-title-tttyyy" effect="light">
                        <div slot="content" v-html="scope.row.title"></div>
                        <p class="text-overflow-2 subject-text" v-html="getTitle(scope.row.title)"></p>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="category_name" label="试题分类" align="center"></el-table-column>
            <el-table-column prop="type_name" label="题型" align="center"></el-table-column>
            <el-table-column prop="difficulty_name" label="难度" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editSubject(scope.row.theory_topic_id)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="deleteSubject(scope.row.theory_topic_id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            class="pages-center"
            style="margin: 20px 0 30px"
            :current-page="subjectPages.currentPageNum"
            :page-size="subjectPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="subjectPages.total"
            @current-change="subjectCurrentChange"
        >
        </el-pagination>

        <el-dialog
            title="导入试题模板"
            :visible.sync="importDialogStatus"
            width="500px"
            @close="handleClose"
            custom-class="green-dialog"
        >
            <div class="upload-file-content">
                <div class="downloadTemp">
                    <a class="theme-hostFontColor" href="/jding/theorydownload" download="试题模板">下载导入试题模板</a>
                </div>
                <el-button type="primary" class="upload-btn" size="medium">
                    <span class="file-txt">选择文件</span>
                    <input
                        type="file"
                        accept=".xls, .xlsx"
                        title=""
                        class="file-input"
                        multiple
                        @change="chooseFile($event)"
                        name="file"
                        ref="fileInput"
                    />
                </el-button>
                <div class="file-list">
                    <ul class="files">
                        <li class="file" v-for="(item, index) in files" :key="index">
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="confirmImport">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { alCate, theoryTopicGetList, theoryTopicExcel, theoryTopicDel } from "@/utils/apis.js";
import _ from "underscore";
export default {
    name: "MaterialExamSubject",
    created() {
        this.getSpecializedList();
        this.getSubjectList();
    },
    data() {
        return {
            subjectTab: "",
            //分类选择
            category_id: [],
            classList: [],
            keyObj: {
                label: "name",
                value: "id",
                children: "children",
            },
            //题型选择
            type_id: "",
            subjectTypeList: [
                {
                    value: 1,
                    label: "单选题",
                },
                {
                    value: 2,
                    label: "多选题",
                },
                {
                    value: 3,
                    label: "判断题",
                },
            ],
            // 题目难度
            difficulty_id: "",
            topicDeepList: [
                {
                    value: 1,
                    label: "容易",
                },
                {
                    value: 2,
                    label: "较易",
                },
                {
                    value: 3,
                    label: "普通",
                },
                {
                    value: 4,
                    label: "较难",
                },
                {
                    value: 5,
                    label: "困难",
                },
            ],
            //搜索
            like: "",
            //试题列表
            subjectListTable: [],
            //分页
            subjectPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
            },
            importDialogStatus: false,
            files: [],
            tmplUrl: "",
        };
    },
    methods: {
        importBtn() {
            this.importDialogStatus = !this.importDialogStatus;
        },
        addBtn() {
            this.$router.push("/theory/add");
        },
        chooseFile(event) {
            if (this.files.length) {
                this.files = [];
            }
            let fileName = event.target.files[0].name;
            let limiteFileType = ["xls", "xlsx"];
            if (!limiteFileType.includes(fileName.split(".")[fileName.split(".").length - 1])) {
                this.$message.warning("文件格式错误！");
                return;
            }
            this.files.push(event.target.files[0]);
        },
        confirmImport() {
            if (this.files.length === 0) {
                return this.$message.warning("请选择文件");
            }
            let formData = new FormData();
            formData.append("theory_topic_excel", this.files[0]);
            theoryTopicExcel(formData)
                .then((res) => {
                    this.$message.success(res.msg);
                    this.importDialogStatus = false;
                    this.getSubjectList();
                })
                .catch((err) => {});
        },
        handleClose() {
            this.files = [];
            this.importDialogStatus = false;
            this.$refs.fileInput.value = "";
        },
        //分类选择
        changeClass() {
            this.subjectPages.currentPageNum = 1;
            this.getSubjectList();
        },
        //题型选择
        changeType() {
            this.subjectPages.currentPageNum = 1;
            this.getSubjectList();
        },
        // 题型难易筛选
        changeDeep() {
            this.subjectPages.currentPageNum = 1;
            this.getSubjectList();
        },
        //搜索
        changeSearch() {
            this.subjectPages.currentPageNum = 1;
            this.getSubjectList();
        },
        //编辑试题
        editSubject(id) {
            //获取试题id
            this.$router.push({
                path: "/theory/add",
                query: { id: id },
            });
        },
        //删除试题
        deleteSubject(id) {
            this.$confirm("是否删除该试题，删除完将无法恢复", "提示消息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                customClass: "green-message-box",
                type: "warning",
            })
                .then(() => {
                    let formData = new FormData();
                    formData.append("theory_topic_id", id);
                    theoryTopicDel(formData)
                        .then((res) => {
                            this.$message.success(res.msg);
                            this.getSubjectList();
                        })
                        .catch((err) => {});
                })
                .catch(() => {
                    this.$message.info("已取消删除");
                });
        },
        //分页
        subjectCurrentChange(val) {
            this.subjectPages.currentPageNum = val;
            this.getSubjectList();
        },
        //专业分类
        getSpecializedList() {
            let params = {
                type: 3,
            };
            alCate(params).then((res) => {
                this.clearEmptyCate(res.data);
                this.classList = res.data;
            });
        },
        clearEmptyCate(arr) {
            arr.forEach((item) => {
                item.children.length <= 0 ? this.$delete(item, "children") : this.clearEmptyCate(item.children);
            });
        },
        //试题列表
        getSubjectList() {
            let params = {
                paging: 1,
                page: this.subjectPages.currentPageNum,
                pageSize: this.subjectPages.eachPageNum,
            };
            if (this.like) {
                params.like = this.like;
            }
            if (this.category_id[this.category_id.length - 1]) {
                params.category_id = this.category_id[this.category_id.length - 1];
            }
            if (this.type_id) {
                params.type_id = this.type_id;
            }
            if (this.difficulty_id) {
                params.difficulty_id = this.difficulty_id;
            }
            theoryTopicGetList(params).then((res) => {
                this.subjectListTable = res.data.list;
                this.subjectPages.total = res.data.total;
                if (this.subjectPages.total !== 0 && this.subjectListTable.length === 0) {
                    this.subjectPages.currentPageNum--;
                    this.getSubjectList();
                }
            });
        },
        //过虑
        getTitle(row) {
            row = row.replace(/<img(.*?)>/g, "[图片]");
            return row;
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep p {
    margin: 0;
}
.main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.subject-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .select-left {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .select {
            margin-left: 40px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
/*.subject-content {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    height: calc(100% - 61px);*/
/*}*/
/*.el-pagination {*/
/*    padding: 10px 5px 0;*/
/*}*/
.upload-file-content {
    .upload-btn {
        position: relative;
        margin-top: 30px;
        .file-txt {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20px;
        }

        .file-input {
            opacity: 0;
            width: 120px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .file-list {
        margin: 10px 0;

        ul,
        li {
            margin: 0;
            padding: 0;
        }

        .files {
            .file {
                list-style: none;
                padding-left: 10px;
            }
        }
    }
}
.searchInput {
    ::v-deep .el-input-group__append {
        padding: 0 8px;
        background: #2dc079;
        .el-button {
            i {
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
}
</style>

<style lang="scss">
.subject-title-tttyyy {
    max-width: 40% !important;
    * {
        color: #333 !important;
        background: transparent !important;
        text-decoration: none !important;
    }
    img {
        max-width: 100px !important;
        max-height: 100px !important;
    }
}
</style>
